.mt-4{
    margin-bottom: 50px;
}

/* Filtro2.css */
  .filtro-container {
    background-image: url('/public/assets/img/fotoia2.png');
    background-size: cover; /* Ajusta la imagen para cubrir todo el contenedor */
    background-repeat: no-repeat; /* Evita que la imagen se repita */
    background-position: center ; 
    padding: 9rem;
    margin-bottom: 20px;
  }
  .filtro-container label {
    color: #fefbfb;
  }

  @media (max-width: 750px) {
    .filtro-container {
      padding: 0;
      padding-top: 9rem;
      padding-bottom: 9rem;
      padding-right: 2rem;
      padding-left: 2rem;

    }
  }

  .btn-bordo {
    background-color: #9D202D !important;
    color: #fff; /* Color del texto */
    /* Agrega otros estilos según sea necesario */
  }
  
  .btn-bordo:hover {
    background-color: #6d151d !important; /* Color hover (cuando pasas el mouse) */
    /* Agrega otros estilos hover según sea necesario */
  }