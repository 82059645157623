
/* Estilo para pantallas pequeñas */
@media (max-width: 991px) {
    .footer-section {
      width: 100%;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
    }
  }
  
  /* Estilo para pantallas medianas y grandes */
  @media (min-width: 992px) {
    .footer-container {
      display: flex;
      justify-content: space-between;
    }
  }

  @media (max-width: 410px) {
    .footer-section iframe{
      display: none;
    }
  }
  