/* FiltroHome.css */
.filtro-btn {
        outline: none !important;
        box-shadow: none !important;
  }

  .btn-bordo {
      background-color: #9D202D !important;
      color: #fff; /* Color del texto */
      /* Agrega otros estilos según sea necesario */
    }
    
    .btn-bordo:hover {
      background-color: #6d151d !important; /* Color hover (cuando pasas el mouse) */
      /* Agrega otros estilos hover según sea necesario */
    }


    