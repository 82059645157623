


.contenedorInframe {
    width: 100%;
    height: 1600px;  
    border: none;  
}


@media (min-width: 1920px) {
    .contenedorInframe {
        height: 1600px;  
    }
  }
  
  @media (min-width: 1024px) {
    .contenedorInframe {
        height: 1800px;  
    }
  }
  
  @media (max-width: 900px) {
    .contenedorInframe {
        height: 3000px;  
    }
  }
  
  @media (max-width: 400px) {
    .contenedorInframe {
        height: 3500px;  
    }
  }
  
  @media (max-width: 200px) {
    .contenedorInframe {
        height: 4000px;  
    }
  }