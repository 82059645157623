.img-fluid {
    width: 100%;
    height: 100%;
  }
  .contact-all {
    background-image: url('../../../public/assets/img/img-contacto.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .tasaciones-all {
    background-image: url('../../../public/assets/img/tasaciones.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  
/*   Mensaje de enviado     */
/* Estilos para la superposición */
.overlay1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Fondo oscurecido */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998;
}

/* Estilos para la ventana modal */
.modal1 {
  background: #18d60bdb; /* Color de fondo de la ventana modal */
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  
  z-index: 9998;
}
.btn-bordo {
  background-color: #9D202D !important;
  color: #fff; /* Color del texto */
  /* Agrega otros estilos según sea necesario */
}

.btn-bordo:hover {
  background-color: #6d151d !important; /* Color hover (cuando pasas el mouse) */
  /* Agrega otros estilos hover según sea necesario */
}

.in-line {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.in-line {

}
.modal-header {
  display: flex;
  margin-right: -20px;
  justify-content: flex-end;
}

.close {
  font-size: 24px;
  cursor: pointer;
}

.close:hover {
  color: red; /* Cambia de color al pasar el mouse */
}
/*    final  */

h1 {
    margin-bottom: 40px;
}

label {
    color: rgb(0, 0, 0);
}

.btn-send {
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    width: 80%;
    margin-left: 3px;
    }
.help-block.with-errors {
    color: #ff5050;
    margin-top: 5px;

}
.card-form{
    margin-top: 10rem !important;
    background-color: rgba(255, 255, 255, 0.59) !important;
}

  @media (min-width:  992px) {
    .card-form {
        min-width: 700px;

      }
}


#contact-form {
    margin-right: 10px; /* Agregamos un margen a la derecha del formulario */
  }
