.inicio {
    min-height: 100vh;
    width: 100%;
  }
  body {
    margin: 0;
    padding: 0;
    max-width: 100%;
    overflow-x: hidden; /* Evita el desplazamiento horizontal */
  }
  .inicio-img {
    height: 800px;
    filter: brightness(0.7);
    width: 100%;
  }
  /*INSTAGRAM*/

  .eapps-instagram-feed-title-container a{
    background-color: transparent !important;
    color: transparent !important;
    display: none;
  }
  .eapps-widget-toolbar-panel * {
    display: none !important;
    pointer-events: none !important;
    display: none;
}
.eapps-widget-toolbar-panel a {
  pointer-events: none !important;
  opacity: 0 !important;
  display: none;
}

.eapps-widget-toolbar-panel-wrapper{
  pointer-events: none !important;
  display: none;
  background-color: transparent !important;
  color: transparent !important;
}
.eapps-widget-toolbar-panel-share *{
  pointer-events: none !important;
  display: none;
  background-color: transparent !important;
  color: transparent !important;
}
.eapps-widget-toolbar * {
  pointer-events: none !important;
  display: none;
  background-color: transparent !important;
  color: transparent !important;
}
.eapps-instagram-feed-popup-mobile-panel-title{
  pointer-events: none !important;
  background-color: transparent !important;
  color: transparent !important;
  display: none;
}


  /*          */
  .home-text-container {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    /* text-shadow: 2px 2px #000; */
  }
  
  .inicio-titulo {
    margin-bottom: 2%;
  }
  .inicio-parrafo {
    margin-bottom: 3%;
  }
  .inicio img {
    min-height: 100vh;
    width: 100%;
  }

  
  .destacado {

    margin-top: 30px;
    margin-bottom: 20px;
  }
  
  @media (min-width: 1920px) {
    .home-text-container {
      width: 100%;
      position: absolute;
      top: 35%;
      font-size: 1.5rem;
    }
    .inicio-titulo {
      font-size: 5rem !important;
    }
    .inicio-parrafo {
      font-size: 2.2rem;
    }
    .inicio img {
      width: 100%;
      object-fit: cover;
    }
  }
  
  @media (min-width: 1024px) {
    .home-text-container {
      position: absolute;
      top: 40%;
      width: 100%;
      font-size: 1.5rem;
    }
    .inicio-titulo {
    
      font-size: 3.2rem;
    }
  
    .inicio img {
      width: 100%;
      object-fit: cover;
    }
  }
  
  @media (max-width: 900px) {
    .home-text-container {
      position: absolute;
      top: 38%;
      font-size: 1.4rem !important;
    }
    .inicio-titulo {
      font-size: 2.3rem !important;
    }
    .inicio img {
      width: 100%;
      object-fit: cover;
    }
  }
  
  @media (max-width: 400px) {
    .home-text-container {
      font-size: 2rem;
    }
    .inicio-titulo {
      font-size: 1.5rem !important;
    }
    .inicio img {
      width: 100%;
      object-fit: cover;
    }
  }
  
  @media (max-width: 200px) {
    .home-text-container {
      font-size: 1.5rem;
    }
    .inicio-titulo {
      font-size: 1rem !important;
    }
    .inicio img {
      width: 100%;
      object-fit: cover;
    }
  }