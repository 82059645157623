
  .fixed-right {
    position: sticky;
    width: 100px;
    bottom: 0;
    transform: translateY(-10%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -100px;
  }
  
  .whatsapp {
    height: 50px;
    width: 50px;
  }
  .fixed_{
    flex-direction: column;
  }
  /* Aplicar estilos cuando el ancho de la pantalla sea menor a 700px */
  @media (max-width: 725px) {
    .fixed_ {
      flex-direction: row; /* Cambia a fila */

    }
    .fixed-right {
      position: relative;

      width: 100%;
      flex-direction: row; /* Cambia a fila */
      justify-content: center; /* Centra en el centro de la pantalla */
      margin-top: 5rem; /* Elimina el margen superior */
      align-items: center; /* Centra verticalmente */
    }
  }
  