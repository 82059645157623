

.propiedades {
  margin-top: 100px;
  margin-left: 4rem;
  margin-right: 4rem;

}

@media (max-width: 450px){
  .propiedades {
    margin-right: 0;
    margin-left: 0;
  }
}
