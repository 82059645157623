.navbar {
    background-color: transparent;
    color: rgb(0, 0, 0); 
    font-size: 1.3rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    max-width: 100%; /* Limita el ancho máximo de la barra de navegación al ancho de la pantalla */

    transition: background-color 0.3s ease, color 0.3s ease; /* Transición suave para el cambio de fondo y color de las letras */
  }



  .logo{
    height: 60px;
    width: 60px;
  }
  /* Estilo para las letras cuando el fondo se vuelve negro */
  .navbar.navbar-dark {
    background-color: black; /* Fondo negro cuando el fondo es negro */
    color: white; /* Letras blancas cuando el fondo es negro */
  }

  /* Estilo para las letras cuando el fondo se vuelve negro */
  .nav-link {
    color: black; /* Letras negras en estado inicial */
  }

  .navbar.navbar-dark .nav-link {
    color: white; /* Letras blancas cuando el fondo es negro */
  }


  .letra{
    font-size: small;
  }
  .linea{
    margin: 0;
    background-color: black;
  }
  .wlinea{
    margin: 0;
    background-color: white;
  }

.borrar{
  visibility: hidden;
}
  @media (max-width: 990px) {
    .borrar {
      display: none !important;
    }
  }
 

